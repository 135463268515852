import React from 'react'
import { graphql } from 'gatsby'
import { DiscussionEmbed } from "disqus-react"
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Comments from '../components/Comments'

import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import Suggested from '../components/Suggested'
import SEO from '../components/SEO'

import config from '../utils/config'

export default function PostTemplate({ data, pageContext, location, ...props }) {
  const post = data.markdownRemark
  const { previous, next } = pageContext
  const { image } = post.frontmatter

  return (
    <Layout>
      <Helmet title={`${post.frontmatter.title} | ${config.siteTitle}`} />
      <SEO postPath={post.fields.slug} postNode={post} postSEO />

      <header className="article-header">
        <div className="container">
          <div className="medium">
            {image && (
              <Img
                fixed={image.childImageSharp.fixed}
                className="post-image"
              />
            )}
            <h1>{post.frontmatter.title}</h1>
            <h6>{post.frontmatter.date}</h6>
          </div>
        </div>
      </header>
      <div className="container">
        <section className="grid post">
          <article>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />

            <Comments
              identifier={post.fields.slug}
              title={post.frontmatter.title}
              url={`https://irenapopova.com${location.pathname}`} />
          </article>
          <Sidebar post={post} {...props} />
        </section>
        <Suggested previous={previous} next={next} />
      </div>
    </Layout>
  )
}

/* const disqusConfig = {
 shortname: process.env.GATSBY_DISQUS_NAME,
 config: { identifier: slug, title },
}
return (
 <Global>
   <PageBody>
     <DiscussionEmbed {...disqusConfig} />
   </PageBody>
 </Global>
) 
 */

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        image {
          childImageSharp {
            fixed(width: 150, height: 150) {
              src
            }
          }
        }
      }
    }
  }
`
