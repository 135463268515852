import React from 'react'
import { DiscussionEmbed } from "disqus-react"


/* export default function Comment({ commentBox }) {
  return <div ref={commentBox} className="comments" />
} */
export default class extends React.Component {
  render() {
    const disqusShortname = "irenapopova-com"
    const disqusConfig = {
      url: this.props.url,
      identifier: this.props.identifier,
      title: this.props.title

    }

    return (
      <div className="article-container">

        {/* <h1>Page Title</h1> */}

        {/* <p>Page content.</p> */}

        <DiscussionEmbed
          shortname={disqusShortname}
          config={disqusConfig}
        />
      </div>
    )
  }
}