import React from 'react'
import { Link } from 'gatsby'

import patreon from '../../content/thumbnail/patreon-light.png'
import coffee from '../../content/thumbnail/ko-fi.png'
import rss from '../../content/thumbnail/rss.png'
import email from '../../content/images/email.png'
import dev from '../../content/thumbnail/dev.png'
import learning from '../../content/thumbnail/learning.png'
import codecrunchies from '../../content/thumbnail/codecrunchies.png'
import twitter from '../../content/thumbnail/twitter.png'
import irena from '../../content/images/irena.png'

import SearchForm from '../components/SearchForm'

import { slugify } from '../utils/helpers'

export default function Sidebar({ post, ...props }) {
  const { tags } = post.frontmatter

  return (
    <aside>
      <div className="aside-content">
        <section>
          {/* <h3>Web Developer</h3> */}
          <div className="squareWrapper avatarWrapper">
            <div className="squareWrapperInner">
              <img src={irena} className="avatar" alt="Me" />
            </div>
          </div>
          <p>
            <h3> 👋  Hi & Welcome in my Blog page! </h3> I am <Link to="/about">Irena</Link>, a Web developer, Linguist & Teacher. This website is a collection of learning materials.  All I have studied throughout the years while learning how to write code. 👩🏻‍💻 🤓
          </p>
          <nav>
            <a
              href="https://irena.substack.com/subscribe"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <img src={email} alt="Email" />
              <span>Newsletter signup</span>
            </a>

            {/** =====DEV========= */}
            <a
              href="https://dev.to/irenejpopova"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <img src={dev} alt="Dev" /> <span>Read more on DEV</span>
            </a>


            {/** ==== RSS======*/}

            {  /* <Link
              to="/rss.xml"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <img src={rss} alt="RSS" /> <span>RSS </span>
           </Link> */}

            {/** ===Buy me a Ko-Fi ========*/}
            <a
              href="https://ko-fi.com/irenapopova"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <img src={coffee} alt="Coffee" /> <span>Buy me a coffee</span>
            </a>

            {/** ====== Patreon ======= */}
            <a
              href="https://www.patreon.com/user?u=39820305"
              target="_blank"
              rel="noreferrer"
              className="link patreon"
            >
              <img src={patreon} alt="Patreon" /> <span>Become a Member</span>
            </a>
            {/** ==========Twitter ============ */}
            <a
              href="https://twitter.com/irenejpopova"
              target="_blank"
              rel="noreferrer"
              className="link twitter"
            >
              <img src={twitter} alt="Twitter" /> <span>Twitter</span>
            </a>

          </nav>
        </section>
        {/**==== Published ================================ */}

        <section>
          <h3>Published</h3>
          <time>{post.frontmatter.date}</time>
        </section>
        {/**Tags */}
        <section>
          <h3>Tags</h3>
          <div className="cell tags">
            {tags &&
              tags.map((tag) => (
                <Link
                  key={tag}
                  to={`/tags/${slugify(tag)}`}
                  className={`tag-${tag}`}
                >
                  {tag}
                </Link>
              ))}
          </div>
        </section>
        {/** USEFUL Links  */}

        <section>
          <div>
            <h3>Useful Links</h3>

            <a
              href="https://github.com/DCI-TechEd"
              target="_blank"
              rel="noreferrer"
              className="link"
            >

              <img src={learning} alt="Learning" /> <span>Workshops DCI-TechEd</span>
            </a>

            <a
              href="https://github.com/CodeCrunchies"
              target="_blank"
              rel="noreferrer"
              className="link"
            >

              <img src={codecrunchies} alt="Learning" /> <span>Practice</span>
            </a>
          </div>
        </section>



        {/**=============Links E - Books ============= */}
        <section>
          <h3>E-Book</h3>
          <div className="readers">
            <a
              href="https://irena-popova.gitbook.io/boost-your-css-skills/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >

              <img src={codecrunchies} alt="Learning" /> <span>Boost Your CSS Skills</span>
            </a>

            <a
              href="https://irena-popova.gitbook.io/flexbox/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >

              <img src={codecrunchies} alt="Learning" /> <span>FlexBox</span>
            </a>
          </div>
        </section>
        {/**==============CHEAT-SHEETS =====================*/}
        <section>
          <h3>CheatSheets</h3>
          <div className="cheatsheets">
            <a
              href="https://irenapopova.github.io/technical-documentation-page/#Introduction"
              target="_blank"
              rel="noreferrer"
              className="link"
            >

              <img src={codecrunchies} alt="Learning" /> <span>JavaScript</span>
            </a>

            <a
              href="http://jsdocs.surge.sh/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >

              <img src={codecrunchies} alt="Learning" /> <span>JavaScript-Arrays</span>
            </a>
            <a
              href="https://irenapopova.github.io/technical-doc-page/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >

              <img src={codecrunchies} alt="Learning" /> <span>AJAX Basics</span>
            </a>
          </div>
        </section>

        {/** ======Learning Lab====== */}
        <section>
          <h3>CodeCrunchies Lab</h3>
          <h4>Practice </h4>
          <div className="readers">
            <a
              href="https://github.com/CodeCrunchies/LearningLab/tree/master/express-node-server-side-form-validation"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <img src={codecrunchies} alt="Learning" /> <span>Express Server</span>
            </a>

            <a
              href="https://github.com/CodeCrunchies/FlexBox-Tutorial"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <img src={codecrunchies} alt="Learning" /> <span>FlexBox</span>
            </a>
          </div>
        </section>
        {/** ====Search============  */}
        <section>
          <h3>Search</h3>
          <p>Search anything on the site.</p>
          <SearchForm {...props} />
        </section>
      </div>
    </aside >
  )
}
